function keep_last_position(reset = false) {
  // ---- เก็บค่าตำแหน่งหน้าจอก่อนการรีเฟรช
  localStorage.setItem("scrollpos", reset ? 0 : window.scrollY);
}
document.addEventListener("DOMContentLoaded", (event) => {
  // ---- เลื่อนหน้าจอไปยังตำแหน่งก่อนการรีเฟรช
  var scrollpos = localStorage.getItem("scrollpos");
  if (scrollpos) window.scrollTo(0, scrollpos);
  keep_last_position(true);
});

document.addEventListener("scroll", handleScroll);
// get a reference to our predefined button
const scrollToTopBtn = document.querySelector(".scrollToTopBtn");
scrollToTopBtn.addEventListener("click", scrollToTop);

function handleScroll() {
  const scrollableHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  const GOLDEN_RATIO = 0.25;
  if (document.documentElement.scrollTop / scrollableHeight > GOLDEN_RATIO) {
    //show button
    if (!scrollToTopBtn.classList.contains("showScrollBtn")) scrollToTopBtn.classList.add("showScrollBtn");
  } else {
    //hide button
    if (scrollToTopBtn.classList.contains("showScrollBtn")) scrollToTopBtn.classList.remove("showScrollBtn");
  }
}
function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}
